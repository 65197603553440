/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/nunito-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Regular'), local('Nunito-Regular'),
       url('./fonts/nunito-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/nunito-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/nunito-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/nunito-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/nunito-v11-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

body {
  background-color: #e9e9e996;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h5 {
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.btn {
  /* font-size: 12px; */
  font-weight: 600;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
  fill: #ffffff;
  color: #ffffff;
  background-color: #005587;
  border-radius: 0px 0px 0px 0px;
  border-color: rgba(0,85,134,.85);
}

.btn:hover {
  background-color: #00386d;
}

.form-group {
  margin-bottom: 0.3rem;
}

.input-group-text {
  min-width: 112px;
}

@media (min-width: 576px){
 .jumbotron {
    padding: 2rem !important;
  }
}

:root {
  --amplify-primary-color: #0a5587;
  --background-color-active: #1189da;
}

.table td, .table th {
  padding: .5rem
}

div[class*="-MenuList"] div[class*="-option"] {
  padding: 0.1rem 0.8rem 0.1rem 0.8rem;
}